export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'id',
    label: 'field.createdDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'bidDate',
    sortable: true,
    sortField: 'bidDate',
    label: 'field.auctionDate',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'startDate',
    label: 'field.startTime',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'endDate',
    label: 'field.endTime',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'location',
    label: 'field.location',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'zone',
    label: 'field.zone',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'vehicleCount',
    label: 'field.vehicleQuantity',
    thClass: 'text-left',
    tdClass: 'text-left',
    defaultValue: 0,
  },
  {
    key: 'vehicleBidCount',
    label: 'field.vehicleBidded',
    thClass: 'text-left',
    tdClass: 'text-left',
    defaultValue: 0,
  },
  {
    key: 'bidderCount',
    label: 'field.totalDealer',
    thClass: 'text-left',
    tdClass: 'text-left',
    defaultValue: 0,
  },
  {
    key: 'bidCount',
    label: 'field.totalBid',
    thClass: 'text-left',
    tdClass: 'text-left',
    defaultValue: 0,
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'actions',
    label: 'field.action',
  },
];
