export default [
  {
    key: 'bidDate',
    label: 'field.auctionDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
  },
  {
    key: 'locationId',
    label: 'field.location',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'location',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'status',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: 'schedule.1', value: '1' },
      { text: 'schedule.2', value: '2' },
      { text: 'schedule.3', value: '3' },
      { text: 'schedule.4', value: '4' },
    ],
  },
]