export default [
  {
    key: 'locationId',
    label: 'field.location',
    rules: 'required',
    type: 'asyn-single-selection',
    repository: 'location',
    selectionKey: 'id',
    selectionLabel: 'name',
    cols: 12,
  },
  {
    key: 'zoneId',
    label: 'field.zone',
    // rules: 'required',
    type: 'asyn-single-selection',
    
    repository: 'zone',
    selectionKey: 'id',
    selectionLabel: 'name',
    cols: 12,
  },
  {
    key: 'bidDate',
    label: 'general.dateSchedule',
    rules: 'required',
    type: 'date-time-picker',
    disableType: 'before',
    cols: 12,
  },
]
