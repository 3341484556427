<template>
  <div>
    <b-modal
      cancel-variant="outline-secondary"
      :cancel-title="$t('button.close')"
      :title="$t('button.newSchedule')"
      centered
      no-close-on-backdrop
      hide-footer
      size="lg"
      v-model="isShow"
    >
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation
          ref="confirmForm"
          key="createForm"
          :form="$refs.createForm"
          ok-variant="success"
          :disabled="invalid"
          @submit="save"
        >
          <n-input :key="`input-${key}`" :fields="formInput" v-model="data">
            <template #zoneId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="item.field.label"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-async-single-select
                    v-model="data[item.field.key]"
                    :query="[
                      {
                        searchby: 'location_id',
                        searchoperator: '=',
                        search: data.locationId,
                      },
                    ]"
                    :ref="item.field.key"
                    :name="item.field.key"
                    :repository="item.field.repository"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    :clearable="item.field.clearable"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  ></n-async-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="button"
                variant="success"
                :loading="loading"
                @submit="submit"
              >
                {{ $t("button.save") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BRow, BCol, BFormGroup } from "bootstrap-vue";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";

const AuctionScheduleRepository = Repository.get("auctionSchedule");

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,

    NFormConfirmation,
    NInput,
    NButtonLoading,
    NAsyncSingleSelect,
  },
  watch: {
    "data.locationId"() {
      this.$refs.zoneId.clear();
    },
    "data.type": function (value) {
      this.updateTitle(value);
      return;
    },
  },
  data() {
    return {
      key: 1,
      isShow: false,
      loading: false,
      data: {
        locationId: null,
        zoneId: null,
        bidDate: null,
      },
      id: null,
    };
  },
  methods: {
    show() {
      this.data = {
        locationId: null,
        zoneId: null,
        bidDate: null,
      };
      this.key++;
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    success() {
      this.$emit("success");
      this.hide();
    },
    submit() {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.$refs.confirmForm.confirm();
        }
      });
    },
    save() {
      this.loading = true;
      AuctionScheduleRepository.create(this.data)
        .then((response) => {
          this.success();
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.createForm.setErrors(error.response?.data?.errors);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const formInput = [...FormInput];

    return {
      formInput,
    };
  },
};
</script>